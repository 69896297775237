import React from 'react';
import styled from 'styled-components';
import { RecommendationsSection } from 'views/home/recommendations-section';
import { Offer } from 'views/offer';
import { Content } from 'views/offer/components/Content';
import { Header } from 'views/offer/components/Header';
import { Image } from 'views/offer/components/Image';
import { ImageWrapper } from 'views/offer/components/ImageWrapper';
import { List } from 'views/offer/components/List';
import { ListItem } from 'views/offer/components/ListItem';
import { Paragraph } from 'views/offer/components/Paragraph';
import { Section } from 'views/offer/components/Section';
import { Subtitle } from 'views/offer/components/Subtitle';
import { getOfferBreadcrumbsLinks } from 'views/offer/helpers';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import { graphql, useStaticQuery } from 'gatsby';
import OtherTreatmentsJson from 'jsons/otherTreatment.json';
import { Layout } from 'layouts';

const SImageWrapper = styled(ImageWrapper)`
  max-width: 1920px;
`;

const Offer9 = ({ location }) => {
  const { pathname } = location;
  const breadcrumbsLinks = getOfferBreadcrumbsLinks(pathname);

  const images = useStaticQuery(graphql`
    query {
      fourth: file(relativePath: { eq: "loyalty.png" }) {
        childImageSharp {
          gatsbyImageData(width: 750, quality: 100, formats: [WEBP])
        }
      }
    }
  `);
  return (
    <Layout>
      <Offer otherTreatments={OtherTreatmentsJson.neauviaExplosion}>
        <Header breadcrumbsLinks={breadcrumbsLinks}>
          Skinbooster Neauvia Explosion
        </Header>
        <Content>
          <Section>
            <SImageWrapper>
              <Image
                src={images.fourth.childImageSharp}
                alt="neauvia explosion mezoterapia igłowa kwas hialuronowy medycyna estetyczna warszawa wola"
              />
            </SImageWrapper>
            <Subtitle>Wskazania do zabiegu Neauvia Explosion</Subtitle>
            <Paragraph>
              Subtelny lifting wolumetryczny, wolumetria twarzy wykonywana przy pomocy dwóch preparatów, usieciowanego kwasu hialuronowego oraz nieusieciowanego kwasu hialuronowego z dodatkiem aminokwasów – glicyny i l-proliny, które z jednej strony <strong>głęboko nawilżają, rewitalizują i stymuluje skórę</strong> do produkcji kolagenu, a z drugiej modelują i uzupełnienią utratę objętości związaną z wiekiem. Zabieg ma efekt nawilżający , skutkuje poprawą napięcia skóry , spłyca pierwsze powierzchowne zmarszczki. Łączy w sobie zalety klasycznej wolumetrii kwasem hialuronowym i zabiegu mezoterapii.
            </Paragraph>
            <Paragraph noMarginBottom>Efekty zabiegu:</Paragraph>
            <List>
              <ListItem>Głębokie nawilżenie</ListItem>
              <ListItem>Modelowanie twarzy</ListItem>
              <ListItem>
                Uzupełnienie utraty gęstości i jędrności skóry związanej z
                wiekiem
              </ListItem>
              <ListItem>Podkreślenie kości policzkowych</ListItem>
              <ListItem>Wypełnienie zmarszczek</ListItem>
            </List>
          </Section>
          <Section>
            <Subtitle>Zabieg Neauvia Explosion</Subtitle>
            <Paragraph>
              Zabieg <strong>Neauvia Explosion</strong> to idealny wybór w celu zadabania o wrażliwą skórę twarzy i dekoltu. <strong>Efekty zabiegu widoczne są natychmiast</strong>, natomiast pełny efekt pojawia się po kilku dniach i utrzymuje się od 12 do 24 miesięcy. Inaczej niż podczas klasycznej wolumetrii, efekt zwiększenia objętości jest mniej zauważalny ponieważ używane są 2 rodzaje kwasu hialuronowego- usieciowany i nieusieciowany. Wchłaniający się kwas zapewnia doskonałe nawilżenie tkanek dając efekt zdrowej i wypoczętej skóry.
            </Paragraph>
          </Section>
          <Section>
            <Subtitle>Cena</Subtitle>
            <Paragraph>
              Cena pojedyncznego zabiegu to 1 100 zł. Przy pierwszej wizycie
              <strong>odbierz swoją kartę lojalnościową i otrzymaj -30%</strong> na czwarty
                zabieg medycyny estetycznej wykonany w Klinice IDEALE.
            </Paragraph>
          </Section>
          <RecommendationsSection />
          <Section>
            <Subtitle>Przeciwwskazania</Subtitle>
            <List>
              <ListItem>Okres ciąży, połogu i karmienia piersią</ListItem>
              <ListItem>Skłonność do powstawania blizn przerostowych</ListItem>
              <ListItem>Nadwrażliwość na kwas hialuronowy</ListItem>
              <ListItem>Choroby nowotworowe</ListItem>
              <ListItem>Zaburzenia krzepnięcia</ListItem>
              <ListItem>Miejscowy stan zapalny skóry</ListItem>
            </List>
          </Section>
          <Section>
            <Subtitle>Dlaczego IDEALE?</Subtitle>
            <Paragraph>
              Zapraszamy do <b>IDEALE Medycyna Estetyczna, Wola, Warszawa</b>,
              gdzie odzyskasz swoją pewność siebie w przyjemnej atmosferze. Nasz
              doświadczony lekarz medycyny estetycznej indywidualnie dopasuje
              odpowiednie dla Ciebie zabiegi medycyny estetycznej, dzięki którym wyjdziesz od nas piękna, naturalna i promienna. Nasza klinika mieści się w samym
              sercu Woli, zapewniając łatwy dostęp dla naszych klientów.
            </Paragraph>
            <Paragraph>
              W IDEALE skupiamy się na dostarczaniu najskuteczniejszych zabiegów
              medycyny estetycznej, które czerpiemy prosto z{' '}
              <b>międzynarodowych kongresów i szkoleń</b>. Dążymy do zapewnienia
              najwyższej jakości usług, a nasz zespół stale poszerza swoją
              wiedzę i umiejętności, aby sprostać Twoim oczekiwaniom.
            </Paragraph>
            <Paragraph>
              Razem z nami odkryjesz potencjał swojej urody i poczujesz się w
              pełni zadbana i zrelaksowana. Zaufaj naszemu doświadczeniu i
              profesjonalizmowi, abyś mógł cieszyć się pięknem, jakie tkwi w
              Tobie.
            </Paragraph>
          </Section>
        </Content>
      </Offer>
    </Layout>
  );
};

export default Offer9;

export const Head = () => <SEO tags={TAGS['neauvia-explosion']} />;
